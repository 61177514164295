/*  "COLORS" STYLES */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $accent-color-1;
}
input {
    color: $accent-color-2;
}
a {
    color: $accent-color-1;
}
a.btn span {
    color: white;
}
a:hover,
a:active,
a:focus {
    color: $accent-color-3;
}
.table {
    .thead-dark {
        th {
            background-color: $accent-color-3;
            border-color: $accent-color-3;
            color: $accent-color-1;
        }
    }
}
.text-red {
    color: red !important;
}
.btn-primary {
    background-color: $accent-color-1;
    border-color: $accent-color-1;
    :hover {
        background-color: $accent-color-2;
        border-color: $accent-color-2;
    }
}
.btn-secondary {
    background-color: $accent-color-3;
    border-color: $accent-color-3;
    :hover {
        background-color: $accent-color-1;
        border-color: $accent-color-1;
    }
}
.btn-green {
    background: #1f6d42;
    color: white;
}
.loading {
    background-color: $accent-color-3;
}
.navbar {
    color: $accent-color-2;
    background: #f3f3f3;
}
.info-bar {
    background: #f1f1f1;
}
.footer-copyright a {
    color: $accent-color-3;
}
.pagination > li > a {
    color: #192a56 !important;
}
.pagination li.active {
    background-color: #dfe6e9;
}
.ng-table-sort-header {
    background: $accent-color-2;
}
/* carrucel home page*/
.carousel-caption {
    background: rgba(0, 0, 0, 0.5);
}
.carousel-caption h5 {
    color: white !important;
}
.far {
    color: green;
}
//*************************** colores background
.bg-accent-color-1 {
    background-color: $accent-color-1 !important;
    color: white !important;
}

.bg-accent-color-2 {
    background-color: $accent-color-2 !important;
    color: white !important;
}

.text-primary {
    color: $accent-color-1 !important;
}
.text-secondary {
    color: $accent-color-2 !important;
}

.text-default {
    color: $gray !important;
}

.text-success {
    color: $success !important;
}

.elegant-color {
    background-color: $gray !important;
}

.elegant-color-dark {
    background-color: $elegant-color-dark !important;
}

.b-white {
    background-color: white;
}

.ReservationModal {
    h3,
    h4 {
        color: $accent-color-3 !important;
    }
    .transport-section {
        background-color: #f9f9f9;
    }
}
#top-bar {
    background: #fff;
    li {
        a {
            color: $accent-color-1;
        }
    }
}
#booking-confirm {
    background-color: #f2f2f2;
}
.modal-modify {
    h4 {
        color: $accent-color-2;
    }
}
