//TIPOGRAFIA

/* FONTS */


$font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-light: 300;

//COLORES
// colores principales segun la identidad corporativa del negocio:
$accent-color-1: #535353;
$accent-color-2: #1dc3f2;
$accent-color-3: #ef445a;

$color-special-1: #cbcd32;

$lightgrey: #f9f9f9;
$border-light: #efefef;

$gray: #2e2e2e;
$elegant-color-dark: #212121;
$success: #46be8a;
